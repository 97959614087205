<template>
<List :relation="true" :tableDatas="tableDatas" :getTableDatas="getTableDatas"></List>
</template>

<script>
import List from '../components/List.vue'
import {relation} from '../api'
export default {
  name: "Relation",
  components: { List },
  data() {
    return {
      tableDatas: [],
    };
  },
  created() {
    this.getTableDatas();
  },
  methods: {
    getTableDatas(order) {
      relation.get({order}).then(res => {
        if(res){
          // 若返回的是数组，则直接赋值
          if(res instanceof Array){
            this.tableDatas = res;
          }else{// 否则替换数组中的元素
            this.tableDatas.splice(0, 1, res);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.el-container {
  padding: 10px;
  background-color: #fff;
  .el-header, .el-main, .el-footer {
    padding: 0;
    border: 1px solid #ebeef5;
  }
  .el-header {
    position: relative;
    box-shadow: none;
    .row-left {
      padding: 0 10px;
      background-color: #fff;
     
    }
    .row-right {
      position: absolute;
      right: 30px;
      top: 16px;
      .el-col {
        width: 26px;
        height: 26px;
        padding: 5px;
        line-height: 16px;
        margin-right: 10px;
        text-align: center;
        color: #333;
        border: 1px solid #ccc;
        cursor: pointer;
      }
    }
  }
  .el-footer{
    padding:7px;
    .el-pagination{
    background-color: #fff;
  }
  }
  
}
</style>